.form-check {
  display: flex;
  align-items: center; /* Center vertically */
  gap: 4px; /* Space between radio button and label */
}

.form-check-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid black; /* Black outer border */
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: white; /* White space between */
  box-shadow: none; /* Disable shadows */
  outline: none; /* Prevent outline on focus */
}

.form-check-input:checked {
  background-color: white; /* White space between */
  border-color: black;
  box-shadow: none; /* Disable shadows */
}

.form-check-input:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: black; /* Black inner circle */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: none; /* Disable shadows */
}

.form-check-input:disabled {
  background-color: #ccc;
  border-color: black;
  cursor: not-allowed;
  box-shadow: none; /* Disable shadows */
}
