.no-border {
  border: none;
}
.patientTabContent {
  .w-65 {
    width: 65%;
  }
  .w-35 {
    width: 35%;
  }
  .linksFlexDiv {
    .activeLink {
      text-underline-offset: 7px;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    .w-65,
    .w-35 {
      width: 100% !important;
    }
  }
}

.status-banner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f0fdf4;
  padding: 5px 15px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.profile_status-text {
  color: #166534;
  font-size: 14px;
  font-weight: 500;
}

.userProfileDetails {
  button {
    cursor: pointer !important;
  }
  .profile_card_body {
    position: relative;
    padding-top: 50px;
  }
  .card-body {
    .border-bottom {
      .card-title {
        font-size: var(--normalFontxm);
      }
      .card-text {
        color: var(--lightBlack);
        font-size: var(--normalFontMd);
      }
    }
    .social-block {
      a {
        color: var(--themeDarkColor);
      }
    }
  }
  .badge {
    @extend .badge;
    padding: 0.4rem 0.625rem !important;
    b {
      font-weight: var(--boldWeight);
    }
    img {
      width: 1rem;
      height: auto;
      margin-right: 0.25rem;
    }
    &.badge-light {
      background-color: var(--lightGrey);
      color: var(--lightBlack);
      i {
        font-style: normal;
        font-weight: var(--lightWeight) !important;
      }
    }
    &.bg-primary {
      background-color: var(--themeDarkColor) !important;
      color: var(--whiteColour) !important;
    }
  }
  .social-block {
    color: var(--themeDarkColor);
    text-decoration: underline;
    img.icon {
      margin-right: 0.25rem;
      width: 1.25rem;
      height: auto;
    }
  }
  .rounded {
    border-radius: 50% !important;
    width: 140px;
    height: 140px;
    object-fit: cover;
    @media screen and (max-width: 1200px) {
      width: 100px;
      height: 100px;
    }
  }
}
@media screen and (max-width: 1180px) {
  .userProfilePart {
    flex-direction: column;
    margin: 0.5rem !important;
    .userProfileDetails {
      width: 100% !important;
      .w-65,
      .w-25 {
        width: 100% !important;
      }
    }
    .userProfileDetails.m-2 {
      margin: 0 0 0.5rem 0 !important;
    }
    .memberShipDetails {
      width: 100% !important;
    }
    .memberShipDetails.m-2 {
      margin: 0.5rem 0 !important;
    }
  }

  // @media screen and (max-width: 768px) {
  //   .userProfilePart {
  //     flex-direction: column;
  //     margin: 0.5rem !important;
  //     .userProfileDetails {
  //       width: 100% !important;
  //       .w-65,
  //       .w-25 {
  //         width: 100% !important;
  //       }
  //     }
  //     .userProfileDetails.m-2 {
  //       margin: 0.5rem 0 !important;
  //     }
  //     .memberShipDetails {
  //       width: 100% !important;
  //     }
  //     .memberShipDetails.m-2 {
  //       margin: 0.5rem 0 !important;
  //     }
  //   }
  @media screen and (max-width: 480px) {
    .userProfileDetails {
      .card-body {
        .user-profile-card {
          flex-direction: column;
        }
      }
    }
  }
}

// Member Ship Details
.memberShipDetails {
  color: var(--lightBlack);
  font-size: var(--normalFontMd);
  font-weight: var(--lightWeight);
  .detailsHeading {
    font-weight: var(--boldWeight);
    color: var(--disabledTextColor);
  }
  .renewealDaysLeft {
    color: var(--errorColor) !important;
    margin-left: 0.25rem;
  }
}
/*memberShipDetails scrool bar*/
.memberShipcard-body {
  padding: 16px 0 16px 16px;
}
.userProfilePart .memberinfo-list {
  overflow-y: scroll;
  max-height: 250px;
}
/* width */
.userProfilePart .memberinfo-list::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.userProfilePart .memberinfo-list::-webkit-scrollbar-track {
  background-color: #d1d5e3;
}
/* Handle */
.userProfilePart .memberinfo-list::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}
/* Handle on hover */
.userProfilePart .memberinfo-list::-webkit-scrollbar-thumb:hover {
  background: #666;
}
/*memberShipDetails scrool bar*/

/*All badge Design*/
.badge {
  padding: 0.25rem 0.625rem;
  font-weight: var(--boldWeight);
  font-size: var(--normalFontSm);
  border-radius: 0.5rem;
  line-height: 1rem;
  &.defaultbadge {
    color: var(--lightBlack) !important;
    background-color: var(--lightGrey) !important;
  }
}
.badge {
  @extend .badge;
  &.bg-success,
  &.carePlanActive,
  &.bg-info,
  &.active,
  &.live,
  &.admin,
  &.enable,
  // &.active.super_admin,
  &.completed {
    color: var(--successDarker) !important;
    background-color: var(--successLighter) !important;
  }
  &.enrolled,
  &.assigned {
    color: var(--primaryDarker) !important;
    background-color: var(--primaryLighter) !important;
  }
  &.lapsed,
  &.subscription_expired,
  &.bg-danger,
  &.health_coach,
  &.busy,
  &.health_coach_team_lead,
  &.health_coach_lead,
  &.to_order {
    background-color: var(--errorLighter) !important;
    color: var(--errorDarker) !important;
  }
  &.text-bg-light,
  &.lightGrayBadge,
  &.logging,
  &.draft,
  &.unpublished,
  &.onboarding_status_default {
    color: var(--lightBlack) !important;
    background-color: var(--lightGrey) !important;
  }
  &.onboardinglist.onboarding,
  &.doctor,
  &.disable,
  &.onboarding,
  &.no.show,
  &.cancelled {
    background-color: #d1d5e3 !important;
    color: #062886 !important;
  }
  &.unpublished {
    background-color: #eef1f3;
    color: var(--lightBlack) !important;
  }

  &.renewal_pending,
  &.ordered_partially {
    background-color: #fffec7 !important;
    color: #937b27 !important;
  }

  &.draft,
  &.payment_incomplete {
    background-color: #ebeffc !important;
    color: #243b86 !important;
  }
  &.in_active {
    background: var(--lightGrey) !important;
    color: var(--lightBlack) !important;
  }
  &.measuring {
    background: #9c9c9c;
    color: var(--whiteColour) !important;
  }
  &.logging.false {
    background: #9c9c9c !important;
    color: var(--whiteColour) !important;
  }
  &.super_admin,
  &.admin,
  &.inactive,
  &.onleave {
    border: 1px solid var(--inputBorderColor);
    color: var(--cardTitle) !important;
    background: none !important;
  }
}
.status-change .badge {
  white-space: normal;
}
/*All badge Design*/

// border Left Design

// border Shadow
.full-width-button {
  padding: 10px;
  background-color: #f3f5fc;
  color: #243b86;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
  text-align: start;
  font-weight: 600;
}

.border-shadow {
  box-shadow: 0px 0px 4px 0px #0000001a;
  padding: 0px 10px 20px 10px;
  border-radius: 8px;
}

.enable-border-left {
  border-left-color: var(--successDarker);
}

.draft-border-left {
  border-left-color: #8a6a16 !important;
}

.unpublished-border-left {
  border-left-color: var(--lightBlack) !important;
}

.in_active-border-left {
  border-left-color: var(--lightBlack) !important;
}

// border Left Design

// Tab Navigation
.tab-nav {
  gap: 2.25rem;
  color: var(--disabledTextColor);
  cursor: pointer;
  .active {
    text-decoration: underline;
    color: var(--themeDarkColor);
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
    cursor: pointer;
  }
}

// Patient Action button row
.patientAction {
  .btn {
    &.btn-outline-primary {
      border-radius: 10px;
      font-weight: var(--semiBoldWeight);
      font-size: var(--semiBoldWeight);
      color: var(--themeDarkColor);
      border-color: var(--themeDarkColor);
      margin-right: 1rem;
      img {
        width: 19px;
        height: auto;
        margin-right: 0.25rem;
      }
      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .linksFlexDiv {
      gap: 10px;
      flex-wrap: wrap;
    }
  }
}
// New Milestone

.headingMilestone {
  div {
    font-weight: var(--lightWeight);
    color: var(--lightBlack);
    b {
      color: #000;
    }
  }
  .goalAchivement {
    font-weight: var(--boldWeight);
    color: var(--successMain);
    img {
      margin-right: 9px;
      margin-top: -3px;
    }
  }
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
}
.newMilestone {
  @media screen and (max-width: 480px) {
    .tagsHolder {
      .badge {
        margin-bottom: 5px;
      }
    }
  }
}
/*Patient card Design*/
.newMilestone {
  padding-left: 15px;
  border-left-style: solid;
  border-width: 4px;
  .mileStone {
    .cardHeading {
      font-size: var(--normalFontMd);
      font-weight: var(--boldWeight);
      b {
        font-style: italic;
      }
      div {
        font-weight: var(--lightWeight);
        font-style: italic;
      }
    }
    .date {
      color: var(--lightBlack);
    }
    .tagsHolder {
      .badge-pill.milestoneAchived {
        border-radius: 35px;
        font-size: var(--normalFontSm);
        font-weight: var(--boldWeight);
        padding: 0 11px 0 4px;
        line-height: 22px;
        margin-right: 10px;
        &.channel {
          padding: 0 11px;
        }
      }
      .badge-pill {
        border-radius: 35px;
        font-size: var(--normalFontSm);
        font-weight: var(--boldWeight);
        padding: 0 11px 0 4px;
        line-height: 22px;
        margin-right: 10px;
        @media screen and (max-width: 768px) {
          margin-bottom: 5px;
          margin-right: 5px !important;
        }
      }
    }
  }

  .progressBar {
    margin-top: 14px !important;
    .progress {
      height: 4px;
    }
  }
}
/*Success Box*/
.success {
  border-color: var(--successMain);
  .badge-light {
    background: var(--successMain);
    color: var(--lightGrey);
  }
  .mileStone {
    background: var(--successLighter);
    .cardHeading {
      color: var(--lightBlack);
    }
    .date {
      color: var(--lightBlack);
    }
    .tagsHolder {
      .badge-pill.milestoneAchived {
        border: solid 1px var(--successMain);
        background-color: var(--whiteColour);
        color: var(--successMain);
      }
      .badge-pill {
        background: var(--lightGrey);
        color: var(--disabledTextColor);
      }
    }
  }
  .progressBar {
    .progress {
      background: #fff;
      .progress-bar {
        background: var(--successMain);
      }
    }
  }
}

.info {
  border-color: var(--primaryMain);
  .badge-light {
    background: var(--primaryMain);
    color: var(--lightGrey);
  }
  .mileStone {
    background: var(--primaryLighter);
    .cardHeading {
      color: var(--lightBlack);
    }
    .date {
      color: var(--lightBlack);
    }
    .tagsHolder {
      .badge-pill.milestoneAchived {
        border: solid 1px var(--primaryMain);
        background-color: var(--whiteColour);
        color: var(--primaryMain);
      }
      .badge-pill {
        background: var(--lightGrey);
        color: var(--disabledTextColor);
      }
    }
  }
  .progressBar {
    .progress {
      background: #fff;
      .progress-bar {
        background: var(--primaryMain);
      }
    }
  }
}

.warning {
  border-color: var(--warningMain);
  .badge-light {
    background: var(--warningMain);
    color: var(--cardTitle);
  }

  .mileStone {
    background: var(--warningLighter);
    .cardHeading {
      color: var(--lightBlack);
    }
    .date {
      color: var(--lightBlack);
    }
    .tagsHolder {
      .badge-pill.milestoneAchived {
        border: solid 1px var(--warningMain);
        background-color: var(--warningMain);
        color: var(--cardTitle);
      }
      .badge-pill {
        background: var(--lightGrey);
        color: var(--disabledTextColor);
      }
    }
  }
  .progressBar {
    .progress {
      background: #fff;
      .progress-bar {
        background: var(--warningMain);
      }
    }
  }
}

.reportTab {
  border-color: #7b858f;
  .badge-light {
    background: #7b858f;
    color: #eef1f3;
  }
  .mileStone {
    background: #eef1f3;
    .cardHeading {
      color: var(--lightBlack);
    }
    .date {
      color: var(--lightBlack);
    }
    .autopsyBtn {
      padding: 12px 24px;
      border-color: #a9b2b9;
      color: var(--lightBlack);
      img {
        margin-right: 10px;
      }
      a {
        color: var(--lightBlack);
        text-decoration: none;
      }
    }
  }
}
/*Patient card Design*/
.weight-card {
  .card-title {
    font-weight: var(--middleWeight);
    font-size: var(--normalFontMd);
    color: var(--lightBlack);
  }
  h6.card-title {
    font-size: var(--normalFontXl);
    display: block;
  }
}
.header-right-part {
  justify-content: flex-end;
  .form-control {
    font-size: var(--normalFontMd);
    color: var(--placeHolderColor);
    line-height: 25px;
    border: 1px solid var(--inputBorderColor);
    border-radius: var(--inputRadius);
    &:focus,
    &:hover {
      border-color: var(--themeColor);
    }
  }
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
select:focus,
[type='text'].form-control:focus,
[type='password'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
[contenteditable].form-control:focus {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.multiselect-container .search-wrapper:hover {
  border: solid 1px var(--themeColor) !important;
}

.multiselect-container .search-wrapper input:focus {
  border-color: transparent;
  box-shadow: none;
}

.progress-value {
  font-size: var(--normalFontSm);
  font-weight: var(--boldWeight);
  color: var(--lightBlack);
  text-align: left;
  margin-left: 15px;
  white-space: nowrap;
}

.cardDataBorder {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-top: 15px;
  border-radius: var(--bs-card-border-radius);
}
.cardDataReportBorder {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  margin-top: 15px;
}
.applyFilterBtn .btnParentDiv {
  padding: 13px 20px;
}

@media only screen and (max-width: 767px) {
  .main-right-part-header {
    margin-top: 8px;
    flex-wrap: wrap;

    .applyFilterBtn {
      margin-bottom: 8px;
    }
  }
}

.filterBtn:hover {
  opacity: 0.7;
}

button.updatetaskbtn {
  background: var(--themeDarkColor);
  cursor: pointer;
  color: var(--whiteColour);
  border: none !important;

  &:hover {
    background: var(--themeDarkColor) !important;
    cursor: pointer;
    color: var(--whiteColour) !important;
    border: none !important;
  }
}
.checkboxlabel label {
  text-align: left;
  display: block;
  margin-bottom: 4px;
  font-size: var(--normalFontMd);
  line-height: 20px;
  font-weight: var(--lightWeight);
  color: var(--lightBlack);
}

.addActivityBtn {
  margin-top: -4px;
}
.addActivityBtn img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.reportCardBorder {
  border: solid 1px #eef1f3;
  border-radius: 10px;
  padding: 32px;
}
.UploadBtnReport {
  white-space: nowrap;
  background-color: #eef1f3;
  border-color: #d9dee2;
  color: #1a2128;
  padding: 6px 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 0.8125rem;
  &:hover {
    background-color: #d8d9da;
    border-color: #d9dee2;
    color: #1a2128;
  }
  img {
    margin-right: 5px;
  }
}
.uploadReportData {
  padding: 0.25rem;
  background-color: #fff;
  max-width: 100%;
  height: auto;
  img {
    height: 30px;
  }
}

.gap-16 {
  gap: 16px;
}
.gap-15 {
  gap: 15px;
}
.searchPic {
  height: 24px;
  margin-right: 10px;
  width: 24px;
}
.userPhoneNumber {
  img {
    width: 17px;
    height: 17px;
  }
}
.emoji-value {
  .show-graph-emoji {
    img {
      height: 25px;
      width: 25px;
      padding: 2px;
      border-radius: 50%;
      background: #7f39d7;
    }
  }
}
.renewalDate {
  input {
    padding: 0;
    padding-left: 5px;
  }
  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused {
    border: none;
    background: none;
    box-shadow: none;
    padding-left: 0;
  }
  .rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
  .rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    padding-left: 0;
  }
  .rs-picker-subtle .rs-btn:focus,
  .rs-picker-subtle .rs-btn:hover,
  .rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-picker-toggle:hover {
    background: none;
    padding-left: 0;
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--lightBlack);
  }
  .rs-picker-date .rs-picker-toggle.rs-btn,
  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-left: 0;
    color: var(--lightBlack);
  }
}

.reportTab {
  .pointer {
    cursor: default !important;
  }
}

.badge.renewalDate {
  border: solid 1px var(--successMain);
  background-color: var(--whiteColour);
  color: var(--successMain);
}

.adherenceGray {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--disabledTextColor);
  border: 1px solid var(--inputBorderColor);
  padding: 4px 10px;
  border-radius: 30px;
  margin: 0px 4px 0 0;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--cardTitle);
  font-weight: var(--boldWeight);
  font-size: var(--normalFontXsm);
  align-items: center;
  img {
    width: 14px;
    margin-right: 5px;
  }
}
.extend-btn {
  background: var(--themeDarkColor);
  border-radius: 6px;
  text-align: center;
  padding: 6px 15px;
  font-size: var(--normalFontSm);
  margin-right: 10px;
  color: #fff;
  border: none;
}
.goalTabPart {
  background-color: #fafafa;
  border: none;
  border-left: solid 2px #b9db72;
  padding: 12px;
  &.disable-border-left {
    border-color: #d1d5e3;
  }
  .goalTital {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .goalIconImg {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    .post-icon-images {
      width: auto;
      height: 30px;
    }
  }
  .threeDot {
    button {
      background-color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }
  .goal-value {
    font-size: 12px;
    color: #757575;
    font-weight: 400;
  }
  .progressBar {
    .progress {
      height: 4px;
    }
  }
  .goalDate {
    font-size: 12px;
    color: #333;
    font-weight: 500;
    button {
      background: none;
      padding: 0;
      margin-left: 5px;
      margin-top: -5px;
    }
  }
  .progressData {
    text-transform: capitalize;
    .progress-value {
      font-weight: 500;
      color: #333;
    }
  }
}
.rs-dropdown-item.rs-dropdown-item-focus,
.rs-dropdown-item:focus-visible {
  background: none;
}
.addButtonTab {
  .btn.btn-outline-primary {
    margin: 0;
    padding: 0;
    border: none;
    margin-right: 0;
  }
}

.created-updated {
  color: #787c80;
  font-size: 12px;
  font-weight: 400;
}

.checkupHead {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.checkupTime {
  color: #163a94;
  font-size: 12px;
}
.checkup-data-part {
  .checkup-entry {
    border: dashed 1px #f0f0f0;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    .checkupIcon {
      border-radius: 3px;
      background-color: #f8f8f8;
      width: 30px !important;
      height: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    .checkup-value {
      font-size: 14px;
      font-weight: 600;
      color: #333;
      padding-left: 15px;
      span {
        font-weight: 400;
      }
    }
  }
}
.goalPopover {
  .dropdown-items-popover {
    display: flex;
    align-items: baseline;
    gap: 7px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    &:hover {
      background-color: #f2f2f2;
    }
    span {
      width: 18px;
      text-align: left;
    }
  }
}
.userDp {
  .userImage {
    &:hover {
      opacity: 0.8;
    }
  }
}

.settingsShowData,
.auditPagination,
.dashboard-pagination {
  .paginationPart {
    justify-content: end !important;
  }
}
.status-change {
  .rs-dropdown-placement-bottom-start {
    width: 100%;
    .rs-dropdown-toggle {
      width: 100%;
      text-align: left;
      text-overflow: ellipsis;
    }
    ul {
      min-width: 100%;
      li {
        &:hover {
          background-color: #f7f7fa;
          color: #333;
        }
      }
    }
  }
}

.rs-modal {
  margin: 0 auto;
}
.white-space-none {
  white-space: initial;
}
.text-left {
  text-align: left !important;
}

/*Nivaan Css*/
.nivaan-items-start {
  align-items: flex-start !important;
}
.nivaan_ms-0 {
  margin: 0 !important;
  text-overflow: ellipsis;
}
.nivaan_flex-column {
  flex-direction: column;
}
.patient-list-show-badge {
  button {
    background: none;
    padding: 0;
    padding-right: 0 !important;
    span {
      color: var(--lightBlack) !important;
      background-color: var(--lightGrey) !important;
      padding: 0.25rem 2rem 0.25rem 0.625rem;
      font-weight: var(--boldWeight);
      font-size: var(--normalFontSm);
      border-radius: 0.5rem;
      line-height: 1rem;
      background-image: url('../../../res/images/editTheme.svg');
      background-position: 95% 3px;
      background-repeat: no-repeat;
      background-size: 18px;
      display: block;
    }
    span.rs-ripple-pond {
      display: none;
    }
    svg {
      display: none;
    }
  }
  &.show {
    button {
      span {
        color: var(--successDarker) !important;
        background-color: var(--successLighter) !important;
      }
    }
  }
  &.no_show {
    button {
      span {
        background-color: #d1d5e3 !important;
        color: #062886 !important;
      }
    }
  }
}
/*Nivaan Css*/

.firstletter_capitalize::first-letter {
  text-transform: capitalize;
}
.prescription-box {
  gap: 20px;
  .spacifictime-box {
    .inputFieldHeader {
      display: none;
    }

    .inputParent {
      width: 100%;

      input {
        border-radius: 10px 0 0 10px;
        border-right: none;
      }
    }

    select {
      border-radius: 0 10px 10px 0;
      margin-bottom: 0 !important;
      min-width: 95px;
    }
  }
  @media screen and (max-width: 1024px) {
    gap: 0;
    flex-direction: column;
  }
}
.add-question-regime-btn {
  width: 20%;
}
@media screen and (max-width: 1180px) {
  .add-question-regime-btn {
    width: 100% !important;
    margin-top: 16px;
  }
}

.notes-list-text pre {
  height: 20px;
  overflow: hidden;
  max-width: 177px;
  text-overflow: ellipsis;
  font-family: Inter, Lato, sans-serif;
}
.regimetemplate-part .rs-picker {
  max-width: 700px;
}
@media screen and (min-width: 1181px) and (max-width: 1400px) {
  .regimetemplate-part .rs-picker {
    max-width: 420px;
  }
}
.specify-time-unit div.rs-picker-select {
  border: 1px solid var(--inputBorderColor);
  height: 39px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-width: 60px;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
}
.specify-time-unit div.rs-picker-select.rs-picker-disabled {
  border: 1px solid var(--inputBorderColor);
  height: 39px;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-width: 60px;
  opacity: 1;
  text-align: center;
  justify-content: center;
  background-color: var(--lightGrey);
}
.not-allowed {
  cursor: not-allowed !important;
}
.specify-time-unit div.rs-picker-select div.rs-picker-toggle {
  background: none !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.consultationtabPart {
  background-color: #ebf5ff;
  border: none;
  border-left: solid 2px #345c84;
  padding: 12px;
  margin-top: 15px;
  border-radius: var(--bs-card-border-radius);
  .consultationTital {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .consultationDate {
    font-size: 12px;
    color: #757575;
    font-weight: 400;
  }
  .consultationbadge .patient-list-show-badge button span {
    background-color: #fff !important;
    border: solid 1px #ccc;
    color: #333;
  }
  .consultationbadge .patient-list-show-badge button {
    text-transform: capitalize;
  }
  .closebtn {
    color: #333;
    border: solid 1px var(--themeDarkColor);
    background: #fff;
  }
  .closebtn:hover {
    background: #133696;
    color: #fff;
  }
}
.diet-regime pre {
  font-family: Inter, Lato, sans-serif;
  font-size: 14px;
}
.optionListContainer {
  z-index: 9 !important;
}
.health-profile-table {
  font-size: 12px;
}
.Dashboardnotification {
  border: solid 1px #d7d7d7;
  max-width: 350px;
  margin-left: 20px;
  border-radius: 6px;
  .notification-title {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 10px;
    color: #333;
  }
  .notification-body {
    overflow: scroll;
    height: 500px;
  }
  .head {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: #edf2ff;
    color: #333;
    border-radius: 6px 6px 0 0;
  }
  .notification-box {
    border-bottom: solid 1px #d7d7d7;
    margin: 10px;
    padding-bottom: 10px;
    .notification-img {
      max-width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #edf2ff;
      display: flex;
      width: 100%;
      margin-right: 10px;
    }
    .notification-text {
      font-size: 12px;
      color: #757575;
      width: 100%;

      span {
        font-size: 14px;
        color: #333;
      }
    }
    .notification-time {
      width: 120px;
      text-overflow: ellipsis;
      color: #415fb1;
      text-align: right;
      font-size: 12px;
    }
    .btn-dismiss {
      background-color: #d9d9d9;
      border-radius: 5px;
      padding: 5px 10px;
      color: #333;
      font-size: 12px;
    }
    .btn-mark {
      background-color: #193990;
      border-radius: 5px;
      padding: 5px 10px;
      color: #fff;
      margin-left: 10px;
      font-size: 12px;
      span {
        font-size: 12px;
        margin-right: 5px;
      }
    }
    &:last-child {
      border: none;
    }
  }
  .view-all-btn {
    color: #133696;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 10px;
    cursor: pointer;
  }
}
.AccordionForm {
  .rs-panel-collapsible {
    margin-bottom: 20px;
    .rs-panel-header {
      background-color: #eaeff2;
      font-weight: 700;
      padding: 10px;
      svg.rs-icon {
        width: 20px !important;
        height: 25px !important;
        top: 10px;
      }
    }
    .rs-panel-collapse {
      border: 1px solid #d9dee2;
      border-radius: 0px 0px 6px 6px;
      border-top: none;
    }
    .rs-picker-read-only {
      .rs-btn-default {
        cursor: default;
      }
    }
  }
}
.webEngage-Tab {
  .badge {
    cursor: pointer;
    &.channelActive {
      background-color: #193990 !important;
      color: #fff !important;
    }
  }
}

.Consultation-box {
  background-color: #edf5fe;
  border-radius: 10px;
  padding: 0;
  border: solid 1px #dadee2;
}
.order-table {
  td {
    .material-icons {
      font-size: 14px;
      color: #193990;
      cursor: pointer;
    }
  }
  th {
    background-color: #f3f3f3;
  }
}

.orderChart {
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  max-width: 500px;
  overflow: hidden;
  .order-chart-card {
    font-weight: 500;
    font-size: 14px;
    color: #757575;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 1px 2px 2px rgba(208, 28, 28, 0.13) inset;
    width: 100px;
    margin-right: 20px;
    &.card-green {
      box-shadow: 0px 1px 2px 2px rgba(104, 170, 82, 0.21) inset;
    }
    &.card-yellow {
      box-shadow: 0px 1px 2px 2px rgba(243, 210, 126, 0.26) inset;
    }
    &.card-gray {
      box-shadow: 0px 1px 2px 2px rgba(191, 189, 189, 0.2) inset;
    }
    span {
      color: #193991;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
.order-popup {
  .order-popup-head {
    font-size: 12px;
    color: #333;
    span {
      .order-date {
        font-size: 10px;
      }
    }
  }
  .order-popup-body {
    border-radius: 10px;
    border: solid 1px #e7e7e7;
    padding: 10px;
    th {
      div {
        position: relative;
        top: 3px;
        font-size: 14px;
        left: 3px;
        cursor: pointer;
      }
    }
    td {
      input {
        border: solid 1px #0000002d;
        max-width: 70px;
        padding: 4px;
        border-radius: 6px;
      }
      tfoot {
        background-color: #f3f3f3;
      }
    }
  }
}
.sortBy-btn {
  button {
    gap: 11px;
    font-size: var(--normalFontMd);
    background-color: var(--bgBtnColor);
    border-radius: var(--btnRadius);
    border: 1px solid var(--themeDarkColor);
    cursor: pointer;
    font-weight: var(--semiBoldWeight);
    color: var(--themeDarkColor);
  }
}
.rs-picker-disabled {
  opacity: 1;
}
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle {
  background-color: #e9ecef !important;
}
.SegmentAction {
  font-family: 'Material Icons Outlined';
  transform: rotate(90deg);
  margin-left: 10px;
  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      box-shadow: none;
    }
    img {
      width: 22px;
    }
  }
}
.Segmentpopover-list {
  display: flex;
  flex-direction: column;
  span {
    padding: 8px;
    border-bottom: solid 1px #757575;
    &:last-child {
      border: none;
    }
  }
}
.order-dropdown-part {
  position: relative;
  cursor: pointer;
  span.material-icons-outlined {
    font-size: 12px;
  }
}

.order-dropdown {
  position: absolute;
  display: none;
  right: 3px;
}
.order-dropdown.orderList {
  display: block;
}
.order-dropdown ul {
  background-color: #fff;
  border-radius: 10px;
  margin: 0;
  list-style: none;
  cursor: pointer;
  box-shadow: 0px 0 8px #eae9e9;
  padding: 8px;
}
.order-dropdown ul li {
  margin-bottom: 5px;
  padding: 5px;
  border-bottom: solid 1px #eee;
  font-weight: 600;
  &:last-child {
    border: none;
  }
  &:hover {
    background: #f3f3f3;
  }
}

.dotted-border {
  border-bottom: 1px dotted #c8d2ed;
  border-top: none;
}
.ConsultationTimeCard {
  background-color: #fff;
  padding: 5px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
}

.busy.super_admin.bg-white {
  background-color: #fff !important;
}
.JoinRoom iframe {
  width: 100%;
  height: 100vh;
}
.joinRoomBody .articleListSideBar {
  display: none;
}
.patientordertab {
  position: relative;
  .order-dropdown {
    width: 144px;
  }
}
.patientordertabtable {
  tfoot {
    background-color: #f3f3f3;
  }
}

.viewchhat-btn {
  background-color: #133696;
  color: #fff;
  padding: 0.25rem 0.625rem;
  font-weight: var(--boldWeight);
  font-size: var(--normalFontSm);
  border-radius: 0.5rem;
  line-height: 1rem;
  cursor: pointer;
  img {
    width: 14px;
  }
}
.msger-chat {
  padding: 10px;
  background-color: rgba(240, 242, 246, 0.5);
  border-radius: 6px;
  margin-bottom: 10px;
  .msg-img {
    margin: 0 10px 0 0px;
    background-color: #633caa;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 6px;
    padding: 4px;
    &.msg-img-user {
      background-color: rgb(255, 108, 108);
    }
  }
  .msg-text {
    p {
      font-size: 12px;
      margin-top: 10px;
      color: #757575;
    }
  }
  &.msger-chat-bot {
    background: none;
  }
}
.MobileDatePickerinput {
  width: 100%;
}
.MobileDatePickerinput .MuiInputBase-root {
  border: 1px solid var(--inputBorderColor);
  box-shadow: unset;
  height: 39px;
  border-radius: var(--inputRadius);
  color: var(--cardTitle);
  font-size: var(--normalFontMd);
  padding: 0.375rem 0;
}
.MobileDatePickerinput .MuiInputBase-root:hover {
  border-color: var(--themeColor);
}
.MobileDatePickerinput .MuiInputBase-root input {
  padding: 0 0.75rem;
  height: 39px;
  border-radius: var(--inputRadius);
  background-color: #e9ecef;
}
.MobileDatePickerinput fieldset {
  display: none;
}
.MobileDatePickerinput input::placeholder,
.MobileDatePickerinput .MuiInputBase-root.Mui-error input {
  // color: #8e8e93;
  color: #8e8e93 !important;
}

.MobileDatePickerinputCommon {
  width: 100%;
}
.MobileDatePickerinputCommon .MuiInputBase-root {
  border: 1px solid var(--inputBorderColor);
  box-shadow: unset;
  height: 39px;
  border-radius: var(--inputRadius);
  color: var(--cardTitle);
  font-size: var(--normalFontMd);
  padding: 0.375rem 0;
}
.MobileDatePickerinputCommon .MuiInputBase-root:hover {
  border-color: var(--themeColor);
}
.MobileDatePickerinputCommon .MuiInputBase-root input {
  padding: 0 0.75rem;
  height: 39px;
  border-radius: var(--inputRadius);
}
.MobileDatePickerinputCommon fieldset {
  display: none;
}
.MobileDatePickerinputCommon input::placeholder,
.MobileDatePickerinputCommon .MuiInputBase-root.Mui-error input {
  // color: #8e8e93;
  color: #8e8e93 !important;
}
.css-1x5jdmq.Mui-disabled {
  -webkit-text-fill-color: black !important;
}
.articletagshow .optionListContainer {
  bottom: 40px;
}
.appointmentTilelink {
  background-color: #deeaf8;
  border-radius: 20px;
  padding: 2px 8px;
  color: #243b86;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
}
.appointmentTilePart .follow {
  color: #656364;
  border: solid 1px #333;
  border-radius: 36px;
  padding: 3px 10px;
  text-align: center;
}
.appointmentTilePart .follow img {
  width: 14px;
}
.appointmentTilePart .record-btn {
  min-width: 60px;
  border-color: #243b86;
  background-color: #243b86;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #182b6a;
  }
}
.appointmentTilePart .material-icons-outlined {
  cursor: pointer;
}

.day-picker {
  display: flex;
  gap: 10px;
}

.day {
  padding: 8px 8px;
  border: 1px solid #d1dcff;
  border-radius: 5px;
  background-color: white;
  // font-size: 8px;
  color: #656364;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day.selected {
  background-color: #243b86;
  color: white;
  border-color: #243b86;
}

.day:hover {
  border-color: grey;
}

.day-picker-label {
  display: block;
  margin-bottom: 6px;
  color: #434d56;
}

.card-bg {
  background-color: #f3f5fc;
}

.lw-rd {
  border-radius: 8px 8px 0px 0px;
}

.tp-rd {
  border-radius: 0px 0px 8px 8px;
}

.cursor {
  cursor: pointer;
}

.no-text {
  color: #656364;
}

.MuiButtonBase-root.MuiAccordionSummary-root {
  padding: 15px 16px;
  border-bottom: 1px solid #c8d2ed;
}

.MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px dotted #c8d2ed;
}

.save-details {
  background-color: #f3f5fc;
  border-color: #d1dcff;
  color: #243b86;
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.core-btn-form {
  background-color: #243b86;
  border-color: #d1dcff;
  color: #f3f5fc;
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.display-text {
  color: #243b86;
  font-weight: 600;
  align-content: center;
}